import { useState } from "react";
import React from "react";
import { Link } from "gatsby";
import { WelcomeBonusQuery } from "../../prismic/staticQueries/markets/index.js";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Carousel from "react-multi-carousel";
import CountUp from "react-countup";
import { Accordion } from "react-bootstrap";
import banner_image from "../../images/siteImages/home/welcome_bonus.png";
import markets_side_image from "../../images/siteImages/home/bonus-30.png";
import deposit from "../../images/siteImages/forex/MarketIcons/currency_pairs.svg";
import trading from "../../images/siteImages/forex/MarketIcons/forex_market_hours.svg";
import claim from "../../images/siteImages/forex/MarketIcons/veteranexpertise.svg";
//import markets_side_image from "../../images/siteImages/StocksIcons/amazon_icon.png";
import register from "../../images/siteImages/home/register.svg";
import introduce from "../../images/siteImages/home/join-chain.svg";
import earn from "../../images/siteImages/home/earn-money.svg";
import riskfree from "../../images/siteImages/home/riskfree.svg";


const WelcomeBonus = () => {
  const language = useSelector((state) => state.language);
  const WelcomeBonusData = WelcomeBonusQuery(language);
  const [loadAllTerms, setLoadAllTerms] = useState(false);
  console.log("language", WelcomeBonusData);
  const {
    welcome_heading,
    welcome_content,
    demo_link,
    demo_link_text,
    faq_heading,
    all_markets_box,
    // markets_side_image,
    analytic_head,
    three_box,
    questionsbox,
    terms_box
  } = WelcomeBonusData;

  const all_markets_icon = [
    register,
    introduce,
    earn,
    riskfree
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2
    },
  };

  return (

    <Layout >
       <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg pb-5">
        <Container className="">
          <Row className="py-5">
            <Col lg={7}>
              <h2 className="pt-5 my-3 text-bold-md text-black">Welcome Bonus for new clients</h2>
              <p className="text-gray my-3 pb-3">
                Explore this exceptional offer available to you only at Rox Capitals – our exclusive welcome bonus! Here's all the information you need about this seasonal month-on-month offer.<br></br>

                Claim our welcome bonus offer today and power your trading experience with Rox Capitals!
              </p>
              <ul className="ui-list mb-5">
                <li>
                  As a new client of Rox Capitals, you qualify for a generous 50% bonus on your
                    initial deposit.
                </li>
                <li>
                  
                  You can receive up to a $500 bonus, giving you extra funds to start your
                    trading journey with confidence.
                </li>
                <li>
                  
                  With this bonus, your account gets an instant boost, giving you more capital
                    to trade with.
                </li>
                <li>
                  
                  Claiming is easy, make a deposit, and the bonus is ready to be claimed thus
                    simplifying your trading journey.
                </li>
              </ul>
              <Link to={demo_link.text} className="demolink" target="_blank">
                {demo_link_text.text}
              </Link>
            </Col>
            <Col lg={5} className="py-5">
              <img alt="ddd" src={markets_side_image} className="dubai-img" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sectionbg">
        <Container className="">
          <Row className="pt-5 pb-3">
            <Col lg={12}>
              <h2 className="pt-5 my-3 text-bold-md text-black text-center">Get Your 30% Bonus in Four Easy Steps</h2>
              <p className="text-gray my-3 text-center">Register, deposit funds and get your 30% Welcome Bonus to start trading</p>
            </Col>

          </Row>
        </Container>
      </section>
      <div class="timeline">
        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
          <div class="timeline__event__icon ">

            <img alt="ddd" src={register} />
          </div>
          <div class="timeline__event__date">
            1
          </div>
          <div class="timeline__event__content ">
            <div class="timeline__event__title">
              REGISTER
            </div>
            <div class="timeline__event__description">
              <p>Open and verify your account to access exclusive benefits.</p>
              <Link to={demo_link.text} target="_blank">
                {demo_link_text.text}
              </Link>
            </div>
          </div>
        </div>
        <div class="timeline__event animated fadeInUp delay-2s">
          <div class="timeline__event__icon">
            <i class="lni-burger"></i>
            <img alt="ddd" src={deposit} />
          </div>
          <div class="timeline__event__date">
            2
          </div>
          <div class="timeline__event__content">
            <div class="timeline__event__title">
              DEPOSIT FUNDS
            </div>
            <div class="timeline__event__description">
              <p>Add minimum $500 to your account securely and conveniently.</p>
            </div>
          </div>
        </div>
        <div class="timeline__event animated fadeInUp delay-1s">
          <div class="timeline__event__icon">

            <img alt="ddd" src={claim} />
          </div>
          <div class="timeline__event__date">
            3
          </div>
          <div class="timeline__event__content">
            <div class="timeline__event__title">
              CLAIM YOUR BONUS
            </div>
            <div class="timeline__event__description">
              <p>Receive your 30% Welcome Bonus upto $500 instantly upon deposit.</p>
            </div>

          </div>
        </div>
        <div class="timeline__event animated fadeInUp">
          <div class="timeline__event__icon">

            <img alt="ddd" src={trading} />
          </div>
          <div class="timeline__event__date">
            4
          </div>
          <div class="timeline__event__content">
            <div class="timeline__event__title">
              BEGIN TRADING
            </div>
            <div class="timeline__event__description">
              <p>Start trading with boosted capital and capture lucrative opportunities.</p>
            </div>
          </div>
        </div>

      </div>

      <section className="sectionbg p-top-10 pb-5 p-bottom-80 faqs d-none">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h2 className="pt-5 my-3 text-bold-md text-black">Terms and Conditions</h2>
            </Col>
            <Col lg={12}>
              <ul>
                {terms_box.slice(0, loadAllTerms ? terms_box.length : 5).map((reason, reasonInd) => (
                  <li className="text-gray my-3" key={"reasonInd" + reasonInd}>
                    {reason.DemoTerms_content.text}
                  </li>
                ))}
              </ul>
              {!loadAllTerms && (
                <div className="text-center pt-3">
                  <button
                    className="btn btn-primary p-3" style={{ lineHeight: "0", borderRadius: "50%" }}
                    onClick={() => setLoadAllTerms(true)}
                  >
                    <i class="fa fa-plus-circle" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <div className="d-none">
        <section className="sectionbg pb-5">
          <Container className="">
            <Row className="py-5">
              <Col lg={4}>
                <h2 className="pt-5 my-3 text-bold-md text-black">{welcome_heading.text}</h2>
                <p className="text-gray my-3 pb-5">
                  {welcome_content.text}</p>
                <Link to={demo_link.text} className="demolink">
                  {demo_link_text.text}
                </Link>
              </Col>
              <Col lg={3}></Col>
              <Col lg={4} className="py-5">
                <img alt="ddd" src={markets_side_image} className="dubai-img" />
              </Col>
            </Row>
            <Row className="mt-5">
              {all_markets_box.length > 0 && all_markets_box.map((marketmap, marketmapInd) => (
                <Col className="mb-5"
                  lg={3}
                  md={6} key={"marketmapInd" + marketmapInd}>
                  <div className="partner-card h-100 mb-4 mx-0 py-2 px-1">
                    <div className="d-flex flex-column mb-2 align-items-center">
                      <div className="box-icon-1 mb-4">
                        <img alt="ddd" src={all_markets_icon[marketmapInd]} className="icon-width" />
                      </div>
                      <h6 className="text-center mb-0"> {marketmap.all_market_description.text}</h6>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <section className="gradient-bg">
          <Container className="py-5">
            <Row>
              <Col md={12} lg={12}>
                <h3 className="text-white text-center">{analytic_head.text}</h3>
              </Col>
            </Row>
            <div className="d-flex py-3 justify-content-center">
              {three_box.length > 0 && three_box.map((threemap, threemapInd) => (
                <div className="three-card px-3" key={"threemapInd" + threemapInd}>
                  <div className="h-100">
                    <div className="d-flex flex-column align-items-center">
                      <h6 className="mb-3 text-center"> {threemap.three_heading.text}</h6>
                      <h5>{threemap.three_description.text}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </section>
        <section className="sectionbg py-5">
          <Container className="">
            <div className="accordion-styles accordion--one">
              <div className="container">
                <div className="row">
                  <h2 className="pt-2 text-bold-md heading text-center">{faq_heading.text}</h2>
                  <div className="col-lg-12">
                    <div className="accordion accordion_one" id="accordion_one">
                      <div className="accordion-single">
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          {questionsbox.map((item, index) => (
                            <Accordion.Item key={`acc-${index}`} eventKey={index}>
                              <Accordion.Header>
                                {item.question.text}
                              </Accordion.Header>
                              <Accordion.Body>{item.answer.text}</Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(WelcomeBonus);